


.App {
  
  background-color: #282c34;
  width: 60%;  
  
}


.AppMobile {
  
  background-color: #282c34;
  
}
.table{
	border-width:4px;
	border-color:red;
}

.radio-menu{
  align-items: center;
}

td.right{
	text-align: right;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiFormLabel-root {
  color: white;
}

.MuiContainer-fixed{
  color:red;
}


  
.MuiDataGrid-columnHeaders {
  borderBottom: 'none'
}

.MuiDataGrid-cell {
  white-space: normal !important;
  
  borderBottom: 'none',
}

.bottomnav{
  background-color: red;
  height: 40px; 
  position: fixed; 
  display:flex;
  bottom:0%;
  width:100%; 
  
  opacity: 1;
}
    
.hidden-thumb .MuiSlider-thumb {
  display: none;
}

.textbold{
  font-weight: bold;
  color:blue

}

.textnormal{
  font-weight: normal;
}

.topalign{
  padding-left: 0px;
  padding-right: 0px;
  vertical-align:top;
}

.title{ 
  color: primary; 
 
}

.topright {
  position: absolute;
  top: 55px;
  right: 0px;
  font-size: 18px;
}

.topleft {
  position: absolute;
  top: 0px;
  left: 80px;
  font-size: 18px;
  //transform: translate(-50%, 0%);
}

.homeBullet{
  text-align: justify;
}

img.wrapper{
  
  float: right;

}

div.wrapper{
  line-height: 1.4;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: justify;

  background-color: #fff;
  
  
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative; /* Required for absolute positioning */
}



div.wrapper0{
  padding: 30px;
  opacity: 0.9;
  padding-top: 3px;
  text-align: center;
  background-color: #fff;
  margin-bottom: 0px;
  border-radius: 8px;
}

div.wrapper01{
  padding: 30px;
  opacity: 0.9;
  padding-top: 3px;
  text-align: center;
  background-color: #fff;
  margin-bottom: 0px;
  border-radius: 8px;
}

div.wrapperInit{
  
  padding-top: 5px;
  padding-bottom: 0px;
  font-size: large;


  background-color: #fff;
  
  
  
}
.homePaper{
  height: 100%;
  border-radius: 50px;
  
}



div.docs{
  line-height: 1;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: justify;

  background-color: #fff;
  
  
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative; /* Required for absolute positioning */
}

.four-parts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: 100vh; /* Set the height to fill the entire viewport */
  padding-top: 100px; /* Add a top padding */
}

.homeGridItem{
  padding-top: 100px; /* Add a top padding */
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd; /* Add borders for visual separation */
  font-size: 1.5em;
  padding-top: 100px; /* Add a top padding */
}

.homeGrid{
  height: calc(100vh - 64px) 
}

.logohome{
  height: 100px;
  width: 100px;
  float: left;
}

.logo-home1{
  
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  
}

.home-floating-image-center {
  position: absolute;
  opacity: 0.6;
  top: 144px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
}

.home-floating-image-mobile {
  
  opacity: 0.6;
  
  
  transform: translate(0%, 10%);
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
}


body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

header h1 {
  color: #333;
}

header p {
  color: #666;
}

.services {
  margin-bottom: 40px;
}

.service {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.service h3 {
  color: #333;
}

.service p {
  color: #666;
}

.about,
.contact {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.about h2,
.contact h2 {
  color: #333;
}

.about p,
.contact p {
  color: #666;
}

.meal-component { margin-bottom: 10px; }
        .component-title { font-weight: bold; }
        .total-carbs { font-weight: bold; color: green; }