
#Calendar {
  height: 500;
  width: 800;
  margin: auto;
  margin-top:0px;
  margin-bottom: 50px;
  /* background: yellow; */
}

.rbc-month-view{
  flex-basis: 500px !important;
}

.rbc-toolbar  {
  
      
          flex-grow: 1;
 
 
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: 22px;
  color: rgb(37, 66, 197)
}

.rbc-date-cell {
  color: rgb(15, 114, 226);
}

.rbc-today {
  background-color: rgb(196, 226, 250) !important;
}

.rbc-event {
  cursor: pointer;
  padding: 2px 5px;
  background-color: #1976d2;
  border-radius: 5px;
  color: #fff;
}

.css-binzgt {
  margin-top: 14px;
}

.rbc-toolbar button {
  color: #373a3c;
  /* display: inline-block; */
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 0px solid #ccc;
  padding: 0px;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.rbc-btn-group {
  display: inline-block;
  
  white-space: nowrap;
  margin-left: auto; 
  margin-right: auto;
}


.rbc-btn-group2 {
  display: inline-block;
  
  white-space: nowrap;
  margin-left: auto; 
  margin-right: 0;
}



@media (max-width: 480px) {
  .rbc-toolbar {
    display: block !important;
  }
}
